import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {formTokko,updateFields} from '../redux/contactDucks'
import { connect } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';

const Contact = (props) => {
    const [text, setText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const {data} = props
    const {dispatch} = props
    const {from} = props
    const {property} = props
    const {development} = props

    const [datos, setDatos] = useState({
      nombre: "",
      cellphone: "",
      email: "",
      text: "",
    });

    const handleInputChange = (event) => {
        setDatos({
          ...datos,
          [event.target.name]: event.target.value,
        });
    };

    const sendForm = (event) => {
    event.preventDefault();
    if(from === 'development'){
        dispatch(formTokko(getUtm('Consulta Emprendimiento'),development.name,development.id,true))
    }
    if(from === 'property'){
        dispatch(formTokko(getUtm('Consulta Propiedad'),property.address,property.id))
    }
    if(from === 'contact'){
        dispatch(formTokko(getUtm('Consulta Web'),null,null))
    }
    document.getElementById("formContact").reset();

    return toast.success("¡Mensaje enviado correctamente!") 
    }

    useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();

    if(!realEstate?.keys?.captcha){
        setDisabled(false)
    }
    else{
        setDisabled(true)
    }

    }, [datos]);

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              keys {
                captcha
              }
          }
    }`)

    const getUtm = (customs = []) => {
        if(typeof window !== 'undefined'){
            var queryString = window.location.search
            var urlParams = new URLSearchParams(queryString)
            const tags = [customs]
            if(property?.producer){tags.push(property?.producer?.name)}
            for (const [key, value] of urlParams) {
                if(key.includes("source")){tags.push("Origen: " + value)}
                if(key.includes("medium")){tags.push("Pieza: " + value)}
                if(key.includes("campaign")){tags.push("Campaña: " + value)}
            }
            return tags
        }
      }

    return(
        <form onSubmit={sendForm} id="formContact" class="row content-form ajax-form">
            <div class="col-lg-6 mb-lg-4">
                <input type="text" name="nombre" onChange={handleInputChange} placeholder="Nombre y Apellido"  />
            </div>
            <div class="col-lg-6 mb-lg-4">
                <input type="text" name="cellphone" onChange={handleInputChange} placeholder="Teléfono"  />
            </div>
            <div class="col-lg-12 mb-lg-4 pr-lg-3">
                <input type="email" name="email" onChange={handleInputChange} placeholder="Mail*" required />
            </div>

            <div class="col-lg-12 mb-lg-4">
                <textarea name="text" cols="30" rows="5" onChange={handleInputChange} placeholder="Mensaje"></textarea>
            </div>
            <div class="col-lg-12 d-lg-flex justify-content-between mb-5">
                <p className="d-block d-lg-none">*Campo obligatorio.</p>
                <div className="">
                    {
                        realEstate?.keys?.captcha ? 
                        <ReCAPTCHA
                        sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                        onChange ={() => setDisabled(false)}
                        /> 
                        : 
                        '' 
                    }
                     {/* disabled={disabled} */}
                    <input type="submit"  disabled={disabled} value="CONTACTANOS" class="btn btn-blue submit mt-4 w-100" id="submit-info" />
                </div>
                <p className="d-none d-lg-block">*Campo obligatorio.</p>
            </div>
        </form>
    )
}
export default connect(state => ({
    data: state.contact.data
}),null)(Contact);
